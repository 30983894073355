var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"stockForm","visible":_vm.dialog,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.visibility(val); }}},[[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.newForm)?_c('h5',{staticClass:"mb-0"},[_vm._v(" Crear nuevo producto ")]):_c('h5',{staticClass:"mb-0"},[(_vm.formDisabled)?_c('span',[_vm._v("Detalle de producto")]):_c('span',[_vm._v("Editar producto")])]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.closeDialog}})],1),_c('validation-observer',{ref:"formData"},[_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Cliente","label-for":"customer_id"}},[_c('v-select',{attrs:{"id":"customer_id","options":_vm.customers,"required":"","label":"name","reduce":function (val) { return val.id; },"state":errors.length > 0 ? false : null,"disabled":_vm.formDisabled},on:{"input":function($event){return _vm.changeEntity(_vm.formData.customer_id)}},model:{value:(_vm.formData.customer_id),callback:function ($$v) {_vm.$set(_vm.formData, "customer_id", $$v)},expression:"formData.customer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"entidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid mt-1' : 'mt-1',attrs:{"label":"Entidad","label-for":"entity"}},[_c('v-select',{attrs:{"options":_vm.entities,"clearable":"","state":errors.length > 0 ? false : null,"label":"name","disabled":_vm.formDisabled},model:{value:(_vm.formData.entity),callback:function ($$v) {_vm.$set(_vm.formData, "entity", $$v)},expression:"formData.entity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"id cum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":""}},[_vm._v("ID CUM")]),_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"label":"id cum","label-for":"cum_id"}},[_c('b-form-input',{attrs:{"type":"number","id":"cum_id","state":errors.length > 0 ? false : null,"trim":"","placeholder":"ID Medicamento","disabled":""},model:{value:(_vm.formData.cum_id),callback:function ($$v) {_vm.$set(_vm.formData, "cum_id", $$v)},expression:"formData.cum_id"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-dark"},on:{"click":_vm.openCumSearch}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DatabaseIcon"}}),_vm._v(" Datos ")],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.cum_id),expression:"formData.cum_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.formData.cum_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "cum_id", $event.target.value)}}})]}}])}),_c('validation-provider',{attrs:{"name":"cantidad","rules":"required|integer|between:0,9999999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid mt-1' : 'mt-1',attrs:{"label":"Cantidad","label-for":"quantity"}},[_c('b-form-input',{attrs:{"id":"quantity","type":"number","state":errors.length > 0 ? false : null,"trim":"","disabled":_vm.formOption},model:{value:(_vm.formData.quantity),callback:function ($$v) {_vm.$set(_vm.formData, "quantity", $$v)},expression:"formData.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Estado","label-for":"status"}},[_c('v-select',{attrs:{"label":"name","options":[{ id: 0, name: 'INACTIVO' }, { id: 1, name: 'ACTIVO' }],"disabled":_vm.formOption,"reduce":function (val) { return val.id; },"status":errors.length > 0 ? false : null},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-button-toolbar',{staticClass:"p-2",attrs:{"justify":""}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){_vm.newForm ? _vm.store() : _vm.edit()}}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeDialog}},[_vm._v(" Cerrar ")])],1)]],2),_c('cum-search-table',{ref:"cumSearchTable",on:{"selectedCumTable":_vm.putSelectedCumOnForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }