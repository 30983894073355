<template>
  <div>
    <b-sidebar id="stockForm" :visible="dialog" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header
      right @change="(val) => visibility(val)">
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 v-if="newForm" class="mb-0">
            Crear nuevo producto
          </h5>
          <h5 v-else class="mb-0">
            <span v-if="formDisabled">Detalle de producto</span>
            <span v-else>Editar producto</span>
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeDialog" />

        </div>

        <!-- BODY -->
        <validation-observer ref="formData">
          <!-- Form -->
          <b-form class="p-2">
            <!-- Cliente -->
            <validation-provider #default="{ errors }" name="cliente" rules="required">
              <b-form-group label="Cliente" label-for="customer_id" :class="errors.length > 0 ? 'is-invalid' : null">
                <v-select id="customer_id" v-model="formData.customer_id" :options="customers" required label="name"
                  :reduce="val => val.id" :state="errors.length > 0 ? false : null" :disabled="formDisabled"
                  @input="changeEntity(formData.customer_id)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Entidad -->
            <validation-provider #default="{ errors }" name="entidad" rules="required">
              <b-form-group label="Entidad" label-for="entity" :class="errors.length > 0 ? 'is-invalid mt-1' : 'mt-1'">
                <v-select v-model="formData.entity" :options="entities" clearable
                  :state="errors.length > 0 ? false : null" label="name" :disabled="formDisabled"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- ID CUM -->
            <validation-provider #default="{ errors }" name="id cum" rules="required">
              <label for="">ID CUM</label>
              <b-input-group label="id cum" label-for="cum_id" :class="errors.length > 0 ? 'is-invalid' : ''">
                <b-form-input type="number" id="cum_id" v-model="formData.cum_id"
                  :state="errors.length > 0 ? false : null" trim placeholder="ID Medicamento" disabled />
                <b-input-group-append>
                  <b-button variant="outline-dark" @click="openCumSearch">
                    <feather-icon icon="DatabaseIcon" class="mr-50" /> Datos
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              <input type="hidden" v-model="formData.cum_id">
            </validation-provider>

            <!-- Quantity -->
            <validation-provider #default="{ errors }" name="cantidad" rules="required|integer|between:0,9999999999">
              <b-form-group label="Cantidad" label-for="quantity" :class="errors.length > 0 ? 'is-invalid mt-1' : 'mt-1'">
                <b-form-input id="quantity" type="number" v-model="formData.quantity"
                  :state="errors.length > 0 ? false : null" trim :disabled="formOption" />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>



            <!-- Estado -->
            <validation-provider #default="{ errors }" name="estado" rules="required">
              <b-form-group label="Estado" label-for="status" :class="errors.length > 0 ? 'is-invalid' : null">
                <v-select label="name" v-model="formData.status"
                  :options="[{ id: 0, name: 'INACTIVO' }, { id: 1, name: 'ACTIVO' }]" :disabled="formOption"
                  :reduce="val => val.id" :status="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
        <b-button-toolbar justify class="p-2">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="newForm ? store() : edit()">
            Guardar
          </b-button>

          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" @click="closeDialog">
            Cerrar
          </b-button>
        </b-button-toolbar>
      </template>
    </b-sidebar>
    <cum-search-table @selectedCumTable="putSelectedCumOnForm" ref="cumSearchTable" />
  </div>
</template>

<script>
import es from 'vee-validate/dist/locale/es';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import CumSearchTable from '../stock/CumsSearchTable.vue';
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'stockForm',
  components: {
    vSelect,
    CumSearchTable,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disabledInputs: false,
      formData: {
        id: null,
        cum_id: null,
        customer_id: null,
        quantity: null,
        status: null,
        image: null,
        entity: null
      },
      entities: [],
      newForm: true,
      formDisabled: false,
      formOption: false,
      dialog: false,
      customers: [],
      selectedCum: []
    }
  },
  methods: {
    changeEntity(pharmacia) {
      this.formData.entity = null;
      this.getEntities(pharmacia);
    },
    getEntities(pharmacia) {
      this.$http.get(`/entities/selects/` + pharmacia).then((res) => {
        this.entities = res.data.entities;
      });
    },
    putSelectedCumOnForm: function (selectedCum) {
      this.formData.cum_id = selectedCum[0].id || null;
      this.$refs.cumSearchTable.hideModal();
    },
    openCumSearch() {
      this.$refs.cumSearchTable.showModal();
    },
    visibility(val) {
      localize('es', es);
      if (!val) this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm()
    },
    store() {
      localize('es', es);
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$http.post('/stocks', this.formData)
            .then((response) => {
              this.dialog = false;
              this.$emit('refeshTable');
              this.resetForm();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Registro creado`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Se ha creado un nuevo registro`,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      })
    },
    edit() {
      localize('es', es);
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$http.put(`/stocks/${this.formData.id}`, this.formData)
            .then((response) => {
              this.$emit('refeshTable');
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Producto actualizado`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Producto actualizado con éxito`,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      })
    },
    resetForm() {
      this.formData = {
        id: null,
        cum_id: null,
        status: null,
        quantity: 0,
        customer_id: null,
        entity: [],
      }
    },
  },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
